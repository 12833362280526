export default {
    "Categories": "Categories",
    "Search results for &ldquo;{query}&rdquo;": "Search results for &ldquo;{query}&rdquo;",
    "What do our customers say": "What do our customers say",
    "{shop} scores a {average} based on {total} reviews": "{shop} scores a {average} based on {total} reviews",
    "By": "By",
    "Subscribe": "Subscribe",
    "Created by": "Created by",
    "{count} ratings": "{0} 0 ratings|{1} {count} rating|[2,*] {count} ratings",
    "Alternate Products": "Alternate Products",
    "Product Description": "Product Description",
    "Specifications": "Specifications",
    "Related Products": "Related Products",
    "Size Chart": "Size Chart",
    "Size Advice": "Size Advice",
    "Reviews": "Reviews",
    "All about this product": "All about this product",
    "All about the {name}": "All about the {name}",
    "Read more": "Read more",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Rating of <span>&rdquo;{name}&ldquo;</span>",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> based on <span>{count}</span> reviews",
    "Written by {name} on {date}": "Written by {name} on {date}",
    "Thank you for your order!": "Thank you for your order!",
    "Your order has been placed successfully.": "Your order has been placed successfully.",
    "Your ordernumber is: #{order_id}.": "Your ordernumber is: #{order_id}.",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.",
    "Billing address": "Billing address",
    "Shipping address": "Shipping address",
    "Pick up your order at:": "Pick up your order at:",
    "Recent Blog Posts": "Recent Blog Posts",
    "Previous": "Previous",
    "Next": "Next",
    "Tags": "Tags",
    "Products by this post": "Products by this post",
    "Login": "Login",
    "Email address": "Email address",
    "Password": "Password",
    "Confirm password": "Confirm password",
    "Forgot password?": "Forgot password?",
    "Benefits of an account": "Benefits of an account",
    "Create account": "Create account",
    "You are now signed out": "You are now signed out",
    "You are now logged out and will return to our home page within 5 seconds.": "You are now logged out and will return to our home page within 5 seconds.",
    "First name": "First name",
    "Last name": "Last name",
    "Date of birth": "Date of birth",
    "DD": "DD",
    "MM": "MM",
    "YYYY": "YYYY",
    "Male": "Male",
    "Female": "Female",
    "Subscribe to our newsletter": "Subscribe to our newsletter",
    "Welcome {name}": "Welcome {name}",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!",
    "Recent orders": "Recent orders",
    "You have not placed any orders yet": "You have not placed any orders yet",
    "Order #": "Order #",
    "Date": "Date",
    "Ship to": "Ship to",
    "Total": "Total",
    "View order": "View order",
    "Personal settings": "Personal settings",
    "Contact details": "Contact details",
    "Edit": "Edit",
    "Change password": "Change password",
    "Newsletter": "Newsletter",
    "You are subscribed to our newsletter": "You are subscribed to our newsletter",
    "subscribe": "subscribe",
    "Addresses": "Addresses",
    "Default shipping address": "Default shipping address",
    "No default shipping address available": "No default shipping address available",
    "Default billing address": "Default billing address",
    "No default billing address available": "No default billing address available",
    "Edit personal settings": "Edit personal settings",
    "Current password": "Current password",
    "New password": "New password",
    "Confirm new password": "Confirm new password",
    "Save": "Save",
    "Add new address": "Add new address",
    "Company": "Company",
    "Phone number": "Phone number",
    "Address": "Address",
    "Postal code + house number": "Postal code + house number",
    "Postal code": "Postal code",
    "House no.": "House no.",
    "Street": "Street",
    "City": "City",
    "Country": "Country",
    "Select a country": "Select a country",
    "Save address": "Save address",
    "Edit address": "Edit address",
    "Extra Addresses": "Extra Addresses",
    "No addresses available": "No addresses available",
    "Remove": "Remove",
    "Account overview": "Account overview",
    "My orders": "My orders",
    "My reviews": "My reviews",
    "Newsletters": "Newsletters",
    "Wishlist": "Wishlist",
    "Logout": "Logout",
    "Do you receive our newsletter?": "Do you receive our newsletter?",
    "Order {increment}": "Order {increment}",
    "Shipping method": "Shipping method",
    "Payment method": "Payment method",
    "Ordered items": "Ordered items",
    "Item": "Item",
    "SKU": "SKU",
    "Price": "Price",
    "Quantity": "Quantity",
    "Subtotal": "Subtotal",
    "Shipping": "Shipping",
    "Free": "Free",
    "Grand total": "Grand total",
    "Request password": "Request password",
    "You have not reviewed any products yet": "You have not reviewed any products yet",
    "Previously viewed by you": "Previously viewed by you",
    "Apply": "Apply",
    "Cancel": "Cancel",
    "Search for...": "Search for...",
    "More results for {inputQuery}": "More results for {inputQuery}",
    "No results found for {inputQuery}": "No results found for {inputQuery}",
    "Select an option": "Select an option",
    "More": "More",
    "Be inspired": "Be inspired",
    "Active filters": "Active filters",
    "More options": "More",
    "Less options": "Less",
    "to": "to",
    "Enter a correct value": "Enter a correct value",
    "Sizes": "Sizes",
    "Filter": "Filter",
    "Show results": "Show results",
    "Show {totalItems} results": "No results | Show 1 result | Show {totalItems} results",
    "No products were found in this category.": "No products were found in this category.",
    "{count} results": "No results | 1 result | {count} results",
    "Sort by": "Sort by",
    "Latest releases": "Latest releases",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "Price (Ascending)": "Price (Ascending)",
    "Price (Descending)": "Price (Descending)",
    "Choose your {option}": "Choose your {option}",
    "Notify me when my size is back in stock": "Notify me when my size is back in stock",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Thank you for your request, you will be notified by mail when the size is back in stock!",
    "Out of stock notification": "Out of stock notification",
    "Choose your size": "Choose your size",
    "Send": "Send",
    "You don't seem to be logged in, please login to enable stock notifications.": "You don't seem to be logged in, please login to enable stock notifications.",
    "If you don't already have an account, you can register one {here}": "If you don't already have an account, you can register one {here}",
    "here": "here",
    "By {name} on {date}": "By {name} on {date}",
    "Added to cart": "Added to cart",
    "To shopping cart": "To shopping cart",
    "Nice to add": "Nice to add",
    "You may also like this": "You may also like this",
    "Select one of the required options.": "Select one of the required options.",
    "Choose your personalisation options": "Choose your personalisation options",
    "Personalize product": "Personalize product",
    "Personalize product with name and/or number": "Personalize product with name and/or number",
    "Choose your stringing personalisation options": "Stringing",
    "Personalize product with stringing": "String my racket",
    "Personalize product with stringing and/or weight": "Customize racket with stringing and weight",
    "Snaar": "Type of string",
    "Gewicht": "Tension",
    "Choose {option}": "Choose your {option}",
    "Add to cart": "Add to cart",
    "Featured": "Featured",
    "Pay safe": "Pay safe",
    "Write a review": "Write a review",
    "Your rating": "Your rating",
    "name": "name",
    "Enter your name": "Enter your name",
    "description": "description",
    "Short description of your review": "Short description of your review",
    "review": "review",
    "Submit review": "Submit review",
    "Unable to save review": "Unable to save review",
    "Thanks for placing a review!": "Thanks for placing a review!",
    "Shopping cart": "Shopping cart",
    "To checkout": "To checkout",
    "One or more products are out of stock.": "One or more products are out of stock.",
    "Continue shopping": "Continue shopping",
    "You have no product(s) in your shopping cart.": "You have no product(s) in your shopping cart.",
    "Click {url} to continue shopping": "Click {url} to continue shopping",
    "Loading shopping cart items...": "Loading shopping cart items...",
    "Product out of stock": "Product out of stock",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Ordered before 22:00, <span>delivered for free</span> the next day",
    "Sold out! Will be expected at <span>{date}</span>.": "Sold out! Will be expected at <span>{date}</span>.",
    "Will be expected at <span>{date}</span>.": "Will be expected at <span>{date}</span>.",
    "Sold out!": "The article is unfortunately sold out",
    "Expected delivery: {date}": "Will be shipped within {date} business days",
    "Delivery costs": "Delivery costs",
    "Discount": "You save",
    "Payment": "Payment",
    "Complete!": "Complete!",
    "Log in": "Log in",
    "Name": "Name",
    "Choose your country": "Choose your country",
    "House number": "House number",
    "Addition": "Addition",
    "Add.": "Add.",
    "Telephone": "Telephone",
    "Click here to automatically retrieve your address": "Click here to automatically retrieve your address",
    "We have found the following address": "We have found the following address",
    "Invalid address. Are you sure it is correct?": "Invalid address. Are you sure it is correct?",
    "Click here to enter the address manually.": "Click here to enter the address manually.",
    "Select your address": "Select your address",
    "Login with your account": "Login with your account",
    "You have an account. Would you like to {action}?": "You have an account. Would you like to {action}?",
    "login": "login",
    "I want to add a comment": "I want to add a comment",
    "Write your comment": "Write your comment",
    "Without extra fees": "Without extra fees",
    "Credit card": "Credit card",
    "Choose your credit card": "Choose your credit card",
    "The most used payment method in The Netherlands": "The most used payment method in The Netherlands",
    "Bank": "Bank",
    "Choose your bank": "Choose your bank",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Do you already have an account with us? Log in {action} and we will get your data.",
    "Billing address is different from the address above": "Billing address is different from the address above",
    "Sign me up for the newsletter": "Sign me up for the newsletter",
    "Create an account": "Create an account",
    "Payment methods": "Payment methods",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.",
    "Choose a payment method to complete your order": "Choose a payment method to complete your order",
    "Shipping methods": "Shipping methods",
    "Choose a delivery method to proceed to the next step": "Choose a delivery method to proceed to the next step",
    "Overview of your order": "Overview of your order",
    "You can pick up your order at": "You can pick up your order at",
    "Your order will be delivered to": "Your order will be delivered to",
    "Change delivery address": "Change delivery address",
    "Some items in your shopping cart are out of stock. {action}": "Some items in your shopping cart are out of stock. {action}",
    "View products.": "View products.",
    "Previous step": "Previous step",
    "To shipping": "To shipping",
    "Continue": "Continue",
    "Complete order": "Complete order",
    "I have a discount code": "I have a discount code",
    "Your applied coupon code is {couponCodeInput}.": "Your applied coupon code is {couponCodeInput}.",
    "Select a {carrier} pick-up point": "Select a {carrier} pick-up point",
    "Please wait, we are loading the stores ...": "Please wait, we are loading the stores ...",
    "personal ID": "personal ID",
    "{distance} kilometer": "{distance} kilometer",
    "{distance} meter": "{distance} meter",
    "Opening hours": "Opening hours",
    "Enter your postal code": "Enter your postal code",
    "My wishlist": "My wishlist",
    "There are no products in your wishlist": "There are no products in your wishlist",
    "Request return": "Request return",
    "Sign up": "Sign up",
    "Conditions": "Conditions",
    "Instructions": "Instructions",
    "Thanks": "Thanks",
    "Order number": "Order number",
    "Submit complaint": "Submit complaint",
    "Order": "Order",
    "Order Date:": "Order Date:",
    "Items ordered": "Items ordered",
    "Product": "Product",
    "Qty": "Qty",
    "Return": "Return",
    "Do you have a comment for this return?": "Do you have a comment for this return?",
    "Submit return": "Submit return",
    "Accept terms and conditions on return submit note": "Accept terms and conditions on return submit note",
    "Print return ticket": "Print return ticket",
    "Finalize return": "Finalize return",
    "Upload image": "Upload image",
    "No image chosen": "No image chosen",
    "Complaint is a required field.": "Complaint is a required field.",
    "Something went wrong, please try again later": "Something went wrong, please try again later",
    "ordered": "ordered",
    "Can't be returned": "Can't be returned",
    "Already returned": "Already returned",
    "Message": "Message",
    "Submit": "Submit",
    "Contact us": "Contact us",
    "Invalid re-captcha.": "Invalid re-captcha.",
    "Your message has been sent.": "Your message has been sent.",
    "validation.required": "Field '{attribute}' is required",
    "validation.email": "Invalid email address for field '{attribute}'",
    "All {label}": "All {label}",
    "Choose your": "Choose your",
    "Grand Total": "Grand Total",
    "Forgot password": "Forgot password",
    "Successfully created an account, you can log in with your new credentials": "Successfully created an account, you can log in with your new credentials",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "If an account is found with {email}, you will receive an email with a link to reset your password.",
    "Thank you for your order, {name}": "Thank you for your order, {name}",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!",
    "Your order number is: <strong>#{orderid}</strong>": "Your order number is: <strong>#{orderid}</strong>",
    "Best regards, {store}": "Best regards, {store}",
    "We expect to deliver on": "We expect to deliver on",
    "We will send a confirmation by e-mail to": "We will send a confirmation by e-mail to",
    "Do you have any questions?": "Do you have any questions?",
    "Visit our <a href=\"/contact\"> customer service </a>": "Visit our <a href=\"/contact\"> customer service </a>",
    "Prefix": "Prefix",
    "Sir.": "Sir.",
    "Mrs.": "Mrs.",
    "Birthday": "Birthday",
    "I accept the Riverty {url} *": "I accept the Riverty {url} *",
    "terms of payment": "terms of payment",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Closed": "Closed",
    "recommended": "Our choice",
    "This is a secure connection": "This is a secure connection",
    "tomorrow": "tomorrow",
    "Create an account for your next visit!": "Create an account for your next visit!",
    "The benefits of an account:": "The benefits of an account:",
    "Order comments": "Order comments",
    "contact_form.subject": "{name} has filled in the contact form at {store}Name.",
    "Search results": "Search results",
    "new": "New",
    "Pickup point": "Pickup point",
    "Something went wrong while restoring your password": "Something went wrong while restoring your password",
    "Your password has been updated": "Your password has been updated",
    "Please enter a valid code": "Please enter a valid code",
    "The coupon code could not be cancelled, please try again later.": "The coupon code could not be cancelled, please try again later.",
    "Choose your FREE product": "Choose your FREE product",
    "Please wait, your return request is being processed.": "Please wait, your return request is being processed.",
    "Apologies, something went wrong processing your return request.": "Apologies, something went wrong processing your return request.",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.",
    "Status": "Status",
    "Used coupon": "Used coupon",
    "Click here to follow your order": "Click here to track your order",
    "My account": "My account",
    "Reset password": "Reset password",
    "Download invoice": "Download invoice",
    "Choose your color": "Choose your color",
    "Color": "Color",
    "Sorry we're down for maintenance": "Sorry we're down for maintenance",
    "We'll be back shortly": "We'll be back shortly",
    "Your recommended size is currently not available!": "Your recommended size is currently not available!",
    "The cache of has been emptied": "The cache of has been emptied",
    "The cache could not be cleared": "The cache could not be cleared",
    "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
    "All fields must be filled": "All fields must be filled.",
    "To Kiyoh reviews page": "Give your opinion",
    "You already have an account with us. Please log in or continue without an account.": "You already have an account with us. Please log in or continue without an account.",
    "You can create an account after you\u2019ve purchased the order.": "You can create an account after you\u2019ve purchased the order.",
    "Did you mean {email}?": "Did you mean {email}?",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Enter your giftcard number and email address, a new giftcard will be send to this email.",
    "Redeem giftcard": "Redeem giftcard",
    "Giftcard": "Giftcard",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Successfully requested new giftcard. Please check your email for the new giftcard code.",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "Could not collect balance for given giftcard code. Please try again later or contact support.",
    "Add set to cart": "Add set to cart",
    "{name} has been added to your cart.": "{name} has been added to your cart.",
    "Max {count} characters": "Max {count} characters",
    "Personalisation Title": "Personalise your product",
    "Order for your team": "Order for your team",
    "Add player | Add players": "Add player | Add players",
    "Player": "Player",
    "Check if all players meet the required options.": "Check if all players meet the required options.",
    "Not all sizes are selected.": "Not all sizes are selected.",
    "Swipe for more products": "Swipe for more products",
    "Order this set for your entire team!": "Order this set for your entire team!",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "This email address and password combination is unknown.",
    "{qty} pieces for {price} each": "{qty} pieces for {price} each",
    "Tierprices": "Tierprices",
    " - {stock} in stock": " - {stock} in stock",
    "Loading totals summary...": "Loading...",
    "Only {qty} available!": "Only {qty} available!",
    "Something went wrong, contact us for help.": "Something went wrong, contact us for help.",
    "Show more": "Show more",
    "Other sports": "Other sports",
    "Billing- and/or shipping address is not valid": "Please check your address information",
    "Back": "Back",
    "How does it work?": "How does it work?",
    "Remove product": "Remove product",
    "Add product": "Add product",
    "Desired delivery day": "Desired delivery day",
    "This product is in stock": "This product is in stock",
    "Available sizes": "Available sizes",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "The minimum length of this field must be equal to or greater than {length} characters. Leading and trailing spaces will be ignored.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "The password must contain at least {count} different character classes. Character classes: Lower Case, Upper Case, Digits, Special Characters.",
    "The first name is required.": "The first name is required.",
    "The first name must be a valid string.": "The first name must be a valid string.",
    "The first name must be at least 2 characters long.": "The first name must be at least 2 characters long.",
    "The first name must not exceed 255 characters.": "The first name must not exceed 255 characters.",
    "The last name is required.": "The last name is required.",
    "The last name must be a valid string.": "The last name must be a valid string.",
    "The last name must be at least 2 characters long.": "The last name must be at least 2 characters long.",
    "The last name must not exceed 255 characters.": "The last name must not exceed 255 characters.",
    "The email address is required.": "The email address is required.",
    "Please provide a valid email address.": "Please provide a valid email address.",
    "The email address must not exceed 255 characters.": "The email address must not exceed 255 characters.",
    "The day of birth is required.": "The day of birth is required.",
    "The day of birth must be a number.": "The day of birth must be a number.",
    "The day of birth must be at least 1.": "The day of birth must be at least 1.",
    "The month of birth is required.": "The month of birth is required.",
    "The month of birth must be a number.": "The month of birth must be a number.",
    "The month of birth must be at least 1.": "The month of birth must be at least 1.",
    "The year of birth is required.": "The year of birth is required.",
    "The year of birth must be a number.": "The year of birth must be a number.",
    "The year of birth must be at least 4 digits long.": "The year of birth must be at least 4 digits long.",
    "The gender must be either Male or Female.": "The gender must be either Male or Female.",
    "The password is required.": "The password is required.",
    "The password confirmation does not match.": "The password confirmation does not match.",
    "As early as possible": "As early as possible",
    "We expect to deliver your complete order on {date}": "We expect to deliver your complete order on {date}",
    "The payment provider rejected the payment request, please use another payment method.": "The payment provider rejected the payment request, please use another payment method.",
    "The payment is canceled, the order is not paid": "The payment is canceled, the order is not paid",
    "An error occurred during the payment process, please try again or use another payment method.": "An error occurred during the payment process, please try again or use another payment method.",
    "Returns": "Returns",
    "Search your orders": "Search your orders",
    "Search": "Search",
    "No order found with order id {orderId}": "No order found with order id {orderId}",
    "Order placed on": "Order placed on",
    "An error occurred while fetching the order. Please try again later.": "An error occurred while fetching the order. Please try again later.",
    "Choose return reason": "Choose return reason",
    "Size": "Size",
    "I want to return my products": "I want to return my products",
    "By initiating a return, you agree to our return policy.": "By initiating a return, you agree to our return policy.",
    "Download return label": "Download return label",
    "This product has custom personalisation options.": "This product has custom personalisation options.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "An error occurred while retrieving the order. Please check your details or try again later.",
    "Oops, something went wrong": "Oops, something went wrong",
    "My returns": "My returns",
    "Why?": "Why?",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "We recommend that you order <strong>one size larger</strong> than you normally wear.",
    "Do you want to merge your old and new shopping basket?": "Do you want to merge your old and new shopping basket?",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "You already had items in your shopping basket. Would you like to merge them with the items you have now added?",
    "No. Only continue with newly added items": "No. Only continue with newly added items",
    "Yes. Merge and continue.": "Yes. Merge and continue.",
    "I would like to:": "I would like to:",
    "Track my order": "Track my order",
    "Download my invoice": "Download my invoice",
    "Return my products": "Return my products",
    "An error occurred while fetching the return label. Please try again later.": "An error occurred while fetching the return label. Please try again later.",
    "Read our return policy": "Read our return policy"
}