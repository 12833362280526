import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        pdfIsLoading: false,
        formParams: {
            email: '',
            order_number: '',
        },

        orderList: [],
        activeOrderNoResults: false,
        activeOrder: {},
        errorMessage: null,
        labelErrorMessage: null,

        returnPostBody: null,
        returnCompleted: false,

        mappingSettings: {},
    }
};

const state = initialState();

const mutations = {
    SET_IS_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'isLoading', payload);
    },
    SET_FORM_PARAMS(state: object, payload: object): void {
        Vue.set(state, 'formParams', payload);
    },
    SET_ACTIVE_ORDER(state: object, payload: object): void {
        Vue.set(state, 'activeOrder', payload);
    },
    SET_ACTIVE_ORDER_NO_RESULTS(state: object, payload: boolean): void {
        Vue.set(state, 'activeOrderNoResults', payload);
    },
    SET_ERROR_MESSAGE(state: object, payload: string): void {
        Vue.set(state, 'errorMessage', payload);
    },
    SET_LABEL_ERROR_MESSAGE(state: object, payload: string): void {
        Vue.set(state, 'labelErrorMessage', payload);
    },
    SET_POST_BODY(state: object, items: Array<object>): void {
        Vue.set(state, 'returnPostBody', {
            'email': state['formParams']['email'],
            'order_number': state['formParams']['order_number'],
            'items': items.map((item: object) => {
                return {
                    item_id: item['id'],
                    quantity: 0,
                    reason_code: null,
                };
            }),
        });
    },
    SET_POST_BODY_QTY(state: object, payload: object): void {
        const index = state['returnPostBody']['items'].findIndex((item: object) => item['item_id'] == payload['item_id']);
        Vue.set(state['returnPostBody']['items'][index], 'quantity', payload['quantity']);
    },
    SET_POST_BODY_REASON(state: object, payload: object): void {
        const index = state['returnPostBody']['items'].findIndex((item: object) => item['item_id'] == payload['item_id']);
        Vue.set(state['returnPostBody']['items'][index], 'reason_code', payload['reason_code']);
    },
    SET_PDF_IS_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'pdfIsLoading', payload);
    },
    SET_RETURN_COMPLETED(state: object, payload: boolean): void {
        Vue.set(state, 'returnCompleted', payload);
    },
    SET_MAPPING_SETTINGS(state: object, payload: object): void {
        Vue.set(state, 'mappingSettings', payload);
    }
};

const actions = {
    setFormParams({commit}, payload: object): void {
        commit('SET_FORM_PARAMS', payload);
    },
    async fetchOrdersByParams({state, commit, dispatch}): Promise<void> {
        dispatch('resetState');
        commit('SET_IS_LOADING', true);

        try {
            const {data} = await this.$solarClient.post('/returns/fetch/order', {
                params: state['formParams'],
            });

            if (data && data.success == true) {
                commit('SET_ACTIVE_ORDER', data);
                if (data.order.items.length) {
                    commit('SET_POST_BODY', data.order.items);
                }
            }

            if ( ! data || data.success == false) {
                commit('SET_ACTIVE_ORDER_NO_RESULTS', true);
            }
        } catch (error) {
            console.error(error);
            commit('SET_ACTIVE_ORDER_NO_RESULTS', true);
            commit('SET_ERROR_MESSAGE', error.response?.data?.error);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async submitReturn({commit, getters}): Promise<void> {
        commit('SET_IS_LOADING', true);

        try {
            const returnPostBody = {...getters['getReturnPostBody']};
            returnPostBody['items'] = returnPostBody['items'].filter((item: object) => item['quantity'] > 0 && item['reason_code']);
            const {data} = await this.$solarClient.post('/returns/post/order', getters['getReturnPostBody']);

            if (data && data.success == true) {
                commit('SET_RETURN_COMPLETED', true);
            }
        } catch (error) {
            console.error(error);
            commit('SET_ERROR_MESSAGE', 'An error occurred while submitting the order. Please try again later.');
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async fetchReturnLabel({state, commit}): Promise<void> {
        const shipmentId = state.activeOrder.order.active_shipment_id;
        commit('SET_PDF_IS_LOADING', true);
        commit('SET_LABEL_ERROR_MESSAGE', null);

        try {
            const {data} = await this.$solarClient.post('/returns/fetch/return-label', {
                email: state.formParams.email,
                order_number: state.activeOrder.order.increment_id,
                shipment_id: shipmentId,
            }, {responseType: 'blob'});

            if (data) {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(data);
                a.download = `return_label_${state.formParams.order_number}_${shipmentId}.pdf`;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
            }

        } catch (error) {
            console.error(error);
            commit('SET_LABEL_ERROR_MESSAGE', 'An error occurred while fetching the return label. Please try again later.');
        } finally {
            commit('SET_PDF_IS_LOADING', false);
        }
    },
    resetState({commit}): void {
        commit('SET_ACTIVE_ORDER', {});
        commit('SET_ACTIVE_ORDER_NO_RESULTS', false);
        commit('SET_ERROR_MESSAGE', null);
        commit('SET_RETURN_COMPLETED', false);
    },
    setMappingSettings({commit}, payload: object): void {
        commit('SET_MAPPING_SETTINGS', payload);
    },
};

const getters = {
    getIsLoading: (state: object) => state['isLoading'],
    getFormParams: (state: object) => state['formParams'],
    getOrderList: (state: object) => state['orderList'],
    getActiveOrder: (state: object) => state['activeOrder'],
    getActiveOrderNoResults: (state: object) => state['activeOrderNoResults'],
    getIncrementId: (state: object) => state['formParams']['order_number'],
    getErrorMessage: (state: object) => state['errorMessage'],
    getLabelErrorMessage: (state: object) => state['labelErrorMessage'],
    getReturnPostBody: (state: object) => state['returnPostBody'],
    getPdfIsLoading: (state: object) => state['pdfIsLoading'],
    getReturnCompleted: (state: object) => state['returnCompleted'],

    getCanSubmitPost: (state: object) => {
        return state['returnPostBody'] && state['returnPostBody']['items'].some((item: object) => item['quantity'] > 0 && item['reason_code']);
    },
    getMappingSettings: (state: object) => state['mappingSettings'],
};

const Returns = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default Returns;
